.darkBG {
    background-color: rgba(0, 0, 0, 0.2);
    width: 100vw;
    height: 100vh;
    z-index: 0;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    position: absolute;
    
  }
  
  .centered {
    position: fixed;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    
  }
  
  .modal {
    width: 500px;
    height: 400px;
    background: rgb(41, 41, 41);
    color: white;
    z-index: 10;


  }
  
  .modalHeader {
    height: 50px;
    background: rgb(12, 12, 12);
    overflow: hidden;

  }
  
  .heading {
    margin: 0;
    padding: 10px;
    color: #0083a3;
    font-weight: 500;
    font-size: 18px;
    text-align: center;
  }
  